@font-face {
    font-family: 'Cardo';
  	font-display: auto;
    src: url('fonts/Cardo-Regular.woff2') format('woff2'),
        url('fonts/Cardo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
  	font-display: auto;
	src: url('fonts/AvenirNext-Regular.eot');
	src: url('fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/AvenirNext-Regular.woff') format('woff'),
		url('fonts/AvenirNext-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* BUTTONS */
.button {
	text-transform: uppercase;
	padding: 10px 30px;
	font-size: $small;
	letter-spacing: 2px;

	&.blue {
		background: $blauw;
		color: white;
	}

	&.white {
		color: white;
		border-color: white;
	}

	&.green {
		color: white;
		background: $groen;
	}
}

.button.green:hover{
	background: $blauw;
}

.button.green {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
}

img {
	max-width: 100%;
}