// some useful style resets and initial form styling
@import '_normalize.scss';
@import '_essentials.scss';
@import 'components/_responsive.scss';
@import 'components/_variabel.scss';
@import 'components/_standard.scss';
@import 'components/_headfoot.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');

/*FIND YOUR DIAMOND */

.page-id-1466 .intro .middle { margin-bottom: 50px !important; }
.page-id-1466 .p1 { line-height: 35px; }
#field_1_13 label { display: none !important; }

.gform_footer { width: 97.4% !important; }

/* */

.gform_wrapper li { text-align: left !important; text-transform: uppercase !important;   }
.gform_wrapper li label { color: #59aee1 !important; }
.ginput_recaptcha div { margin: auto; }

h2 {
	font-size: 16px !important;
	text-transform: uppercase !important;
	font-weight: 400 !important;
	color: $lblauw !important;
	margin-bottom: 0px !important;
}

body {
	color: $grey !important;
	font-size: 16px !important;
	font-family: $avenir !important;
}

#wrapper {
	position: relative;
}

.maxbreedte {
	width: $desktop;
	margin: auto;

	@media screen and (max-width: $desktop) {
		width: 100%;
	}	
	.row {
		margin: 0px !important;
	}
}


.smallbreedte {
	width: $smallbreedte;
	margin: auto;

	.inhoud  {
		font-size: 18px;
	}
} 

/* BLOG */

.inhoud.max {
	max-width: 80% !important;
	margin: auto;
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.inhoud.max {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: auto;
	@media screen and (min-width: $desktop) {
	height: 538px;
	}
}

/* --- */


/* FORMULIER */

.gform_wrapper input,
.gform_wrapper select,
.gform_wrapper textarea  {
background: #e9f3f9 !important;
border: 0px;
width: 100% !important;
padding: 8px !important;
height: 40px;
border-radius: 0px !important;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

.gform_wrapper {
max-width: 65% !important;
margin: auto !important;
}

.gform_wrapper li {
text-align: center;
}

.gform_wrapper .gform_button {
background: #39AC1F !important;
color: white;
width: 50% !important;
margin: auto !important;
font-size: 15px !important;
}

.gform_wrapper .gform_footer {
text-align: center;
}

/* ---- */

a:hover, a:focus, a:active {
	text-decoration: none !important;
}

p {
	line-height: 25px;
	margin-bottom: 35px !important;
}


/* BANNER BLOK */

#banner.groot video {
	position: absolute;
	width: 100%;

	@media screen and (max-width: 1100px) {
		width: 150%;
	}
}

#banner.groot {
	overflow: hidden;
}

.home {
	#banner {
		@media screen and (max-width: $mobile) {

		}
	}
}

#banner {

	&.klein {
		@media screen and (max-width: $mobile) {
			min-height: 250px;
		}
		min-height: 350px;	

		.inhoud { 
			top: 34% !important;
		}	
	}



	&.groot {
		@media screen and (max-width: $mobile) {
			height: 300px;
		}
		height: 600px;	

	}


	&.film {
		@media screen and (max-width: $mobile) {
			height: 300px;
		}
		height: 600px;	
		position: relative;
	}


		.overlay {
			width: 100%;
			height: 100%;
			position: absolute;
			background: rgba(0, 0, 0, 0.6);
			z-index: 7;
		} 

		.play { 
			i {
				background: $lblauw;
				width: 75px;
				line-height: 75px;
				text-align: center;
				border-radius: 100px;
				color: white;
				margin-bottom: 40px;
				font-size: 30px;
				padding-left: 4px;
			}
		}

		.popup {
			display: none;
		}


	background-size: cover;
	background-position: center center;
	position: relative;

    opacity:0;

	display: flex !important;
	justify-content: center !important; /* align horizontal */
	align-items: center !important; /* align vertical */



		.inhoud {
			text-align: center;
			width: 100%;
			position: relative;
			z-index: 8;
			padding: 0 30px;

				.top {
					font-size: 28px;
					@media screen and (max-width: $mobile) {
						font-size: 17px;
					}
					color: white;
					margin-bottom: 20px;
					font-family: $avenir !important;
					font-weight: 300;
					text-shadow: 1px 1px $blauw;
				}

				.bottom {
					color:  $lblauw;
					font-size: $small;
					text-transform: uppercase;
					letter-spacing: 4px;
					text-shadow: 0.5px 0.5px $blauw;
				}
		}



}

/* POPUP */

.featherlight .featherlight-content {
	background: none !important;
}

.featherlight  {

	.featherlight-close-icon {
		color: white !important;
	}

	.featherlight-content {
		background: none !important;
	}
}

/* TEXT IMAGE OM & OM */

#textimage {
	margin: 100px 0;

	.col-md-6 {
		padding: 0px !important;
		overflow: hidden !important;
	}

	@media screen and (max-width: $mobile+300px) {

		.row {
			display: table;
			width: 100% !important;
			margin: auto !important;
		}

		.foto {
			width: 100% !important;
			float: none !important;
		}

		.inhoudbijfoto {
			display: table-footer-group;
			width: 100%;
			float: none !important;
		}

	}

	@media screen and (max-width: $mobile) {
		.foto {
			min-height: 300px !important;
		}
	}

			.foto:hover, .foto:focus, .foto:active {
			    -webkit-transform: scale(1.1);
			    transform: scale(1.1);
			}
			.foto {
			    display: inline-block;
			    vertical-align: middle;
			    -webkit-transform: perspective(1px) translateZ(0);
			    transform: perspective(1px) translateZ(0);
			    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
			    -webkit-transition-duration: 0.3s;
			    transition-duration: 0.3s;
			    -webkit-transition-property: transform;
			    transition-property: transform;
			    width: 100%;
			}

	.foto {
		min-height: 535px;
		background-size: cover;
	}


	.inhoud {
		text-align: center;
		padding-top: 30%;

		@media screen and (max-width: $mobile+300px) {
				padding-top: 18%;
				padding-bottom: 18%;
		}

		.top {
			color: $lblauw;
			font-size: $small;
			margin-bottom: 5px;
			letter-spacing: $spacing;
		}

		.middle {
			color: $blauw;
			font-size: $head;
			margin-bottom: 30px;
			letter-spacing: $spacing;
		}

		.bottom {
			margin-bottom: 30px;
			font-size: 16px;
		}
	}
}

/* CONTENT BLOCK */

.intro {
	text-align: center;
	text-transform: uppercase;
	.top {
		color: $lblauw;
		font-size: $small;
		margin-bottom: 40px;
		letter-spacing: 4px;
	}

	.middle {
		color: $blauw;
		font-size: $head;
		margin-bottom: 90px;
		letter-spacing: 4px;
		font-size: 18px !important;

		@media screen and (max-width: $mobile) {
			margin-bottom: 60px;
		}
	}		
}

#contentblok {
	margin-top: 100px;

		@media screen and (max-width: $mobile) {
			margin-top: 60px;
		}	

	img {
		max-width: 450px;
		height: auto;
	}
}

/* TWO COLUMN */

#twocolumn {
	clear: both;
	width: 100%;	
	overflow: hidden;
	margin: 100px 0 0 0;

		@media screen and (max-width: $mobile) {
			margin: 60px 0 30px 0;
		}

	h2 {
		margin-top: 0px !important;
		line-height: 26px;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.col-md-6 {
		padding: 0 20px !important;
	}

	&.front {
		img {
			max-width: 425px;
			@media screen and (max-width: $mobile) {
				max-width: 100%;
			}
			height: auto;
		}

		.foto {
			margin-bottom: 50px;
		}

		strong {
			color: $blauw;
		}

		.bullets {
			ul {
				margin: 0px !important;
				padding: 0px !important;
				list-style-type: none;
				li {
					background-image: url('../images/vink.svg');
					background-repeat: no-repeat;
					padding-left: 40px;
					margin-bottom: 10px;
					padding-bottom: 5px;

					&:before {
						display: none;
					}
				}
			}
		}
	}
}

#twocolumn .col-md-6 ul {
	line-height: 25px;
	margin-bottom: 35px;
	list-style: none;
	padding-left: 15px !important;
}

#twocolumn .col-md-6 ul li {
	position: relative;
}

#twocolumn .col-md-6 ul li::before {
	content: "•"; 
	color: $lblauw;
	font-size: 25px;
	position: absolute;
	left: -15px;
}

#twocolumn a {
	color: $lblauw;
	border-bottom: 1px solid $lblauw;
}

/* BANNER */

#button {
	text-align: center;
	margin-bottom: 100px;
	a {
		margin: auto;
	}
}

/* childpages */

#childpages {
	margin-top: 80px;

	.overzicht {
 		text-align: center;
 		ul {
 			list-style-type: none;
 			margin: 0px;
 			padding: 0px;
 		
 				li {
			        display: inline;
			        text-align: center;

			        &.current_page_item {

			        	a {
			        		font-weight: bold;
			        		border-bottom: 1px solid $blauw;
			        	}
			        }

			        
			        a {
			        	color: $blauw;
			        	text-transform: uppercase;
			        	margin: 0px 15px;
			        	font-size: 14px;
			        	letter-spacing: 3px;

			        	&:hover {
			        		border-bottom: 1px solid $blauw;
			        	}
			        }
 				}
 		}
 	}
}

/* VIDEO FRONT */

.gform_wrapper ul li:before {
	display: none !important;
}

/* Video background */

.videobg {
  position: absolute;
  width: 100%; /* Set video container element width here */
  height: 100%; /* Set video container element height here */
  overflow: hidden;
  background: #111; /* bg color, if video is not high enough */
}

/* horizontally center the video */
.videobg-width {
  position: absolute;
  width: 100%; /* Change width value to cover more area*/
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

/* set video aspect ratio and vertically center */
.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 ratio */
  overflow: hidden;
  
}

.videobg-make-height {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

.videobg-hide-controls {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  /* Vimeo timeline and play button are ~55px high */
  padding: 55px 97.7777px; /* 16:9 ratio */
  top: -55px; 
  left: -97.7777px; /* 16:9 ratio */
}

.videobg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0 none;
}

iframe {
	max-width: 100%;
}