/* HEADER */

.shiftnav ul.sub-menu li .shiftnav-submenu-activation {
	display: none !important; 
}

.shiftnav-active.shiftnav-depth-0 {
	margin-bottom: 30px !important;
}

.shiftnav-inner {
	padding: 20px 0px 20px 20px !important;
}

.shiftnav .shiftnav-submenu-activation i.fa {
	color: $lblauw !important;
}

.shiftnav .shiftnav-panel-close {
	background: None !important;
}

 #flymenu {
 	z-index: 999;
 	position: fixed;
 	top: 475px;
 	width: 50px;
 	right: 0px;

 	@media screen and (max-width: 650px) {
		bottom: 0 !important;
		left: 0 !important;
		width: 100%;
		top: inherit;
	}

 		ul {
 			margin: 0px;
 			padding: 0px;
 			list-style-type: none;


 				li {

 	 		  	@media screen and (max-width: 650px) {
			 		width: 25% !important;
			 		float: left !important;
			 	}

 					background: $lblauw;
 					color: white;
 					border-bottom: 1px solid $llblauw;
 					padding: 10px;
 					text-align: center;
 					list-style-type: none;
 					letter-spacing: 2px;

 					&.groen {
 						background: $groen;
 						border-bottom: 1px solid $groen;
 					}
 				}
 		}

	 		 .uitklap {
	 		  	@media screen and (max-width: 650px) {
			 		display: none !important;
			 	}
			    display: none;
			    position: absolute;
			    margin-left: -200px;
			    width: 200px;
			    right: 50px;
			    z-index: 1;
			    color: #666666;
			    font-weight: 300;
				top: 0px;
				height: 195px;

				li {
					font-size: 10px;
					text-align: left;
					padding: 13px 8px 15px 15px;
					width: 200px !important;
 					font-weight: 600;
 					text-transform: uppercase;

 					&.groen {
 					}
				}

			}

			i { font-size: 15px; }
 }


 #header {
  	@media screen and (max-width: 650px) {
 		padding: 20px 0 20px 0px;
 	}
 	padding: 40px 0 0 0px;
 	position: relative;
 		.logo  {
 			text-align: center;
 			text-transform: uppercase;

 			img {
 				height: auto;
 				margin-bottom: 10px;
 				max-width: 337px;
 			}

 			.name {
 				@media screen and (max-width: 650px) {
 					font-size: 25px;
 				}
 				font-size: 35px;
 				color:$blauw;
 				letter-spacing: 5px;
 				margin-bottom: 8px;
 			}

 			.subname {
  				@media screen and (max-width: 650px) {
 					font-size: 10px;
 				}
 				font-size: 15px;
 				color:$lblauw;
 				letter-spacing: $spacing;
 			}
 		}

 	.menu {
 		text-align: center;
 		position: relative;
 		@media screen and (max-width: 650px) {
 			margin: 0;
 		}
 		margin: 40px 0 25px 0;

		li span:hover {
			cursor: pointer !important;
		}



 		ul {
 			list-style-type: none;
 		
 				li {
			        text-align: center;

			        // &.contact {
			        //	margin-left: 10px;
			        //	a {
			        //		background: $blauw;
			        //		color: white;
			        //		// padding: 10px 30px;
			        //		font-weight: bold;
			        //	}
			        //}



			        	&.ubermenu-current-menu-item {
			        		a {
			        			border-bottom: 0px solid $lblauw;
			        			color: $lblauw;
			        		}
			        	}

			        a, span {
			        	color: $blauw;
			        	text-transform: uppercase;
			        	// margin: 0px 15px;
			        	font-size: 14px;
			        	letter-spacing: 2px;
			        	outline: none !important;

			        	&:hover {
			        		border-bottom: 0px solid $blauw;
			        	}
			        }
 				}
 		}
 	}

	.ubermenu .ubermenu-tab-layout-left>.ubermenu-tabs-group {
		border: 0px !important;
	}

	.ubermenu-responsive-toggle {
 		position: absolute;
	    right: 0px;
	    bottom: -16px;
	    z-index: 9999;
    }

    ul.ubermenu-tabs-group li {
		text-align: left !important;
	}

	.ubermenu-responsive-toggle-open {
		top: -32px !important;
	}

	.menublok ul {
		margin: auto !important;
	}

	.menublok ul {
		margin: auto !important;
	}

	.menublok ul li a{
		text-align: left !important;
		padding-left: 0px !important;
	}

	.menublok ul li{
		text-align: left !important;
		padding-left: 0px !important;
	}

	.menuquote {
		font-size: 23px;
	    color: #59aee1;
	    font-family: $avenir !important;
	    line-height: 28px;
	    font-weight: 300;
	    max-width: 90%;

		margin: 0px 0 15px 0 !important;
		padding: 0px !important;
	}
 }


/* FOOOOOTER */

#footeritems {
	background: $blauw;
	clear: both;
	overflow: hidden;
	color: white;
	margin-top: 160px;

	@media screen and (max-width: 650px) {
		display: none;
	}

	.col-md-3 {
		border-right: #36628d 1px solid;



		padding: 23px 0;
		text-align: center;
		text-transform: uppercase;
		font-size: 14px;


		&:nth-last-child(1) {
			border-right: 0px;
		}

		a {
			color: white;
		}
	}

	 i {
		display: block;
		font-size: 25px;
		color: $lblauw;
	}

	span {
		display: block;
		margin-top: 13px;
		font-size: $small - 2px;
		font-weight: bold;
		letter-spacing: 2px;
	}



}

#footer {
	background: rgba(89, 174, 225, 0.6); 
	overflow: hidden;
	padding: 70px 0px;

		.widget {
				@media screen and (max-width: $mobile) {
					margin-bottom: 60px;
				}
			color: white;
			font-size: $small;

			.kop {
				color: $blauw;
				text-transform: uppercase; 
				@media screen and (max-width: $mobile) {
					margin-bottom: 10px;
				}
				margin-bottom: 30px;
				letter-spacing: 4px;
				font-size: $small;
			}

			p {
				line-height: 20px;
			}

			ul {
				list-style-type: none;
				padding: 0px;
				margin: 0px;

				li {
					margin-bottom: 3px;
					a {
						color: white;
						font-size: $small;
					}
				}
			}
		}
}


/* Underline From Left */


.ubermenu-target-title {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  /* overflow: hidden; */
}
.ubermenu-target-title:before,
li.ubermenu-current-page-ancestor span span.ubermenu-target-text:before{
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: -5px;
  background: $blauw;
  height: 1px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.ubermenu-target-title:hover:before,
.ubermenu-target-title:focus:before, 
.uubermenu-target-title:active:before,
li.ubermenu-current-page-ancestor span span.ubermenu-target-text:before {
  right: 0;
}

.ubermenu .ubermenu-submenu-type-stack>.ubermenu-item-normal>.ubermenu-target {
    padding-top: 10p !important;
    padding-bottom: 10p !important;
}


/* RESP MENU */


.shiftnav a {
text-transform: uppercase;
}

.shiftnav ul.sub-menu li {
	padding-left: 20px !important;
}

.shiftnav ul.sub-menu {
	margin-bottom: 30px;
}

.shiftnav:after {
	display: none !important;
}