/* COLORS */
$blauw: #153b63; 
$lblauw: #59aee1; 
$llblauw: #80c8f2;
$grey: #82807f;
$groen: #35ad0d;


/* BORDER RADIUS */
$radius: 5px;

/* FONT SIZES */
$head: 18px;
$small: 12px;


/* FONT */
$cardo: 'Cardo', sans-serif;
$avenir: 'Open Sans', sans-serif;
$minion: 'Minion Pro', sans-serif;

/* MARGINS */
$margintop: 40px;

$spacing: 5px;

/* PADDINGS */
$pad: 80px;

$desktop: 1300px;
$smallbreedte: 960px;

$mobile: 680px;